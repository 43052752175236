import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useFunnelCharacterContext} from "../FunnelContext";

import AnimeFSmallBreast
    from "../../../../pages/SimpleCharacterCreation/images/femaleSpecifics/anime/tits/AnimeFSmallTits.png";
import AnimeFMediumBreast
    from "../../../../pages/SimpleCharacterCreation/images/femaleSpecifics/anime/tits/AnimeFMediumTits.png";
import AnimeFLargeBreast
    from "../../../../pages/SimpleCharacterCreation/images/femaleSpecifics/anime/tits/AnimeFLargeTits.png";
import RealFSmallBreast
    from "../../../../pages/SimpleCharacterCreation/images/femaleSpecifics/real/tits/RealFSmallTits.png";
import RealFMediumBreast
    from "../../../../pages/SimpleCharacterCreation/images/femaleSpecifics/real/tits/RealFMediumTits.png";
import RealFLargeBreast
    from "../../../../pages/SimpleCharacterCreation/images/femaleSpecifics/real/tits/RealFLargeTits.png";
import AnimeFSmallButt
    from "../../../../pages/SimpleCharacterCreation/images/femaleSpecifics/anime/butt/AnimeFSmallButt.png";
import AnimeFMediumButt
    from "../../../../pages/SimpleCharacterCreation/images/femaleSpecifics/anime/butt/AnimeFMediumButt.png";
import AnimeFLargeButt
    from "../../../../pages/SimpleCharacterCreation/images/femaleSpecifics/anime/butt/AnimeFLargeButt.png";
import RealFSmallButt
    from "../../../../pages/SimpleCharacterCreation/images/femaleSpecifics/real/butt/RealFSmallButt.png";
import RealFMediumButt
    from "../../../../pages/SimpleCharacterCreation/images/femaleSpecifics/real/butt/RealFMediumButt.png";
import RealFLargeButt
    from "../../../../pages/SimpleCharacterCreation/images/femaleSpecifics/real/butt/RealFLargeButt.png";

const FemaleBodyPartStep = ({onSelect}) => {
    const {isAnime, funnelCharData, updateCharacterData} = useFunnelCharacterContext();
    const [selectedBreastSize, setSelectedBreastSize] = useState(funnelCharData.breastSize || '');
    const [selectedButtSize, setSelectedButtSize] = useState(funnelCharData.dickSize || '');
    const {t} = useTranslation();

    const bodyParts = {
        breasts: [
            {name: 'Small', image: isAnime ? AnimeFSmallBreast : RealFSmallBreast},
            {name: 'Medium', image: isAnime ? AnimeFMediumBreast : RealFMediumBreast},
            {name: 'Large', image: isAnime ? AnimeFLargeBreast : RealFLargeBreast},
        ],
        butt: [
            {name: 'Small', image: isAnime ? AnimeFSmallButt : RealFSmallButt},
            {name: 'Medium', image: isAnime ? AnimeFMediumButt : RealFMediumButt},
            {name: 'Large', image: isAnime ? AnimeFLargeButt : RealFLargeButt},
        ],
    };

    const handleBreastSelect = (size) => {
        setSelectedBreastSize(size);
        const updatedData = {
            breastSize: size
        };
        updateCharacterData(updatedData);
        if (onSelect && selectedButtSize) {
            onSelect(updatedData);
        }
    };

    const handleButtSelect = (size) => {
        setSelectedButtSize(size);

        const updatedData = {
            buttSize: size
        };
        updateCharacterData(updatedData);
        if (onSelect && selectedBreastSize) {
            onSelect(updatedData);
        }
    };

    return (
        <div className="general-multiple-options-container" style={{marginTop: 'unset'}}>
            <span className="funnel-creation-label">{t('PrivatePartsTitle')}</span>
            <div className="top-row">
                {bodyParts.breasts.map((part, index) => (
                    <div
                        key={`${part.name}-${index}`}
                        className="general-option-wrapper"
                        role="button"
                        tabIndex={0}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                handleBreastSelect(part);
                            }
                        }}
                    >
                        <div
                            className={`general-option-creator-multiple-items ${selectedBreastSize === part.name ? 'general-option-creator-selected' : ''}`}
                            onClick={() => handleBreastSelect(part.name)}
                        >
                            <img
                                src={part.image}
                                alt={part.name}
                                className={`general-image-creator ${selectedBreastSize === part.name ? 'selected' : ''}`}
                                style={{pointerEvents: 'none', objectPosition: 'top'}}
                            />
                            <span className='enforced-display-char-creator-small'
                                  style={{fontSize: '0.65em', left: '10px'}}
                            >{t(part.name)}
                            </span>
                        </div>
                    </div>
                ))}
            </div>

            {/*<span className="funnel-creation-label">{t('tSelectButtSize')}</span>*/}
            <div className="bottom-row">
                {bodyParts.butt.map((part, index) => (
                    <div
                        key={`${part.name}-${index}`}
                        className="general-option-wrapper"
                        role="button"
                        tabIndex={0}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                handleButtSelect(part);
                            }
                        }}
                    >
                        <div
                            className={`general-option-creator-multiple-items ${selectedButtSize === part.name ? 'general-option-creator-selected' : ''}`}
                            onClick={() => handleButtSelect(part.name)}
                        >
                            <img
                                src={part.image}
                                alt={part.name}
                                className={`general-image-creator ${selectedButtSize === part.name ? 'selected' : ''}`}
                                style={{pointerEvents: 'none', objectPosition: 'bottom'}}
                            />
                            <span className='enforced-display-char-creator-small'
                                  style={{fontSize: '0.65em', left: '10px'}}
                            >{t(part.name)}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FemaleBodyPartStep;