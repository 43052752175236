import React, { useState } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useTranslation } from "react-i18next";
import { useFunnelCharacterContext } from "../FunnelContext";
import NailPolish from "./src/nail_polish/Feet_Female_NailPolish.jpg";
import AntiNailPolish from "./src/nail_polish/Feet_Female_NoPolish.png";

const FeetStep2 = ({ onSelect }) => {
    const { funnelCharData, updateCharacterData } = useFunnelCharacterContext();
    const [selectedExtra, setSelectedExtra] = useState(funnelCharData.nailPolish || '');
    const { t } = useTranslation();

    const extraOptions = [
        {
            name: 'Painted Nails',
            image: NailPolish,
        },
        {
            name: 'Natural Nails',
            image: AntiNailPolish,
        }
    ];

    const handleExtraSelect = (extra) => {
        setSelectedExtra(extra.name);

        const updatedData = {
            nailPolish: extra.name
        };

        updateCharacterData(updatedData);

        // Trigger auto-progression
        if (onSelect) {
            onSelect(updatedData);
        }
    };

    return (
        <div>
            <div className="general-multiple-options-container"
                 style={{marginTop: '0'}}
            >
                <div className="funnel-creation-label" style={{marginBottom: '0'}}>
                    {t('painted_nails_question')}
                </div>
                <div className="top-row">
                    {extraOptions.map((option, index) => (
                        <div
                            key={`${option.name}-${index}`}
                            className="general-option-wrapper"
                            role="button"
                            tabIndex={0}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    handleExtraSelect(option);
                                }
                            }}
                        >
                            <div
                                className={`general-option-creator-multiple-items ${selectedExtra === option.name ? 'general-option-creator-selected' : ''}`}
                                onClick={() => handleExtraSelect(option)}
                            >
                                <img
                                    src={option.image}
                                    alt={t(option.name)}
                                    className={`general-image-creator ${selectedExtra === option.name ? 'selected' : ''}`}
                                    style={{
                                        pointerEvents: 'none',
                                        objectPosition: 'bottom',
                                        aspectRatio: '1/1',
                                    }}
                                />
                                <span className='enforced-display-char-creator-small'>
                                    {t(option.name)}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FeetStep2;