import React, { useEffect, useState } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useTranslation } from "react-i18next";
import { useFunnelCharacterContext } from "../FunnelContext";
import WhiteStyle from "./src/ethnicity/Feet_Female_White_Tights.jpg";
import AsianStyle from "./src/ethnicity/Feet_Female_Asian.jpg";
import BlackStyle from "./src/ethnicity/Feet_Female_Black.jpg";

const FeetStep1 = ({ onSelect }) => {
    const { funnelCharData, updateCharacterData } = useFunnelCharacterContext();
    const [selectedEthnicity, setSelectedEthnicity] = useState(funnelCharData.ethnicity || '');
    const { t } = useTranslation();

    const ethnicityOptions = [
        {
            name: 'White',
            image: WhiteStyle
        },
        {
            name: 'Asian',
            image: AsianStyle
        },
        {
            name: 'Black',
            image: BlackStyle
        }
    ];

    // Initialize from existing data
    useEffect(() => {
        if (funnelCharData.ethnicity) {
            setSelectedEthnicity(funnelCharData.ethnicity);
        }
    }, [funnelCharData.ethnicity]);

    const handleEthnicitySelect = (ethnicity) => {
        setSelectedEthnicity(ethnicity);

        // Update both context and parent component
        const updatedData = {
            ethnicity: ethnicity,
        };

        updateCharacterData(updatedData);

        // Trigger auto-progression
        if (onSelect) {
            onSelect(updatedData);
        }
    };

    return (
        <div>
            <div className="general-multiple-options-container"
                 style={{marginTop: '0'}}
            >
                <div className="funnel-creation-label" style={{marginBottom: '0'}}>
                    {t('EthnicityTitle')}
                </div>
                <div className="top-row">
                    {ethnicityOptions.map((option, index) => (
                        <div
                            key={`${option.name}-${index}`}
                            className="general-option-wrapper"
                            role="button"
                            tabIndex={0}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    handleEthnicitySelect(option.name);
                                }
                            }}
                        >
                            <div
                                className={`general-option-creator-multiple-items ${selectedEthnicity === option.name ? 'general-option-creator-selected' : ''}`}
                                onClick={() => handleEthnicitySelect(option.name)}
                            >
                                <img
                                    src={option.image}
                                    alt={t(option.name)}
                                    className={`general-image-creator ${selectedEthnicity === option.name ? 'selected' : ''}`}
                                    style={{
                                        pointerEvents: 'none',
                                        objectPosition: 'center',
                                        aspectRatio: '1/1.5',
                                    }}
                                />
                                <span className='enforced-display-char-creator-small'>
                                    {t(option.name)}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FeetStep1;