import TransStep0 from "./Other/Trans/TransStep0";
import TransStep1 from "./Other/Trans/TransStep1";
import TransStep2 from "./Other/Trans/TransStep2";
import {useTranslation} from "react-i18next";
import FeetStep0 from "./Feet/FeetStep0";
import FeetStep1 from "./Feet/FeetStep1";
import FeetStep2 from "./Feet/FeetStep2";
import GeneralVoiceStep from "./General/GeneralVoiceStep";
import GeneralScenarioStep from "./General/GeneralScenarioStep";
import FemaleBodyPartsStep from "./General/FemaleBodyPartsStep";
import GeneralHairColorStep from "./General/GeneralHairColorStep";

const createFunnelConfig = (t) => ({
        trannyGeneral: [
            {
                component: TransStep0,
            },
            {
                component: TransStep1,
            },
            {
                component: GeneralHairColorStep,
            },
            {
                component: TransStep2,
            },
            {
                component: GeneralVoiceStep,
            },
            {
                component: GeneralScenarioStep,
            }
        ],
        feetGeneral: [
            {
                //style
                component: FeetStep0,
            },
            {
                //race
                component: FeetStep1,
            },
            {
                //hair color
                component: GeneralHairColorStep,
            },
            {
                //body parts
                component: FemaleBodyPartsStep,
            },
            {

                component: FeetStep2,
            },
            {
                //voice selection
                component: GeneralVoiceStep,
            },
            {
                //scenario
                component: GeneralScenarioStep,
            }
        ]
    }
);

export const useStepsConfig = (version) => {
    const {t} = useTranslation();
    const config = createFunnelConfig(t);
    return config[version] || [];
};