import React, { useEffect, useState } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useTranslation } from "react-i18next";
import { useFunnelCharacterContext } from "../FunnelContext";
import { useLocation } from "react-router-dom";
import AnimeStyle from "./src/style/Feet_Female_Anime.jpg";
import RealStyle from "./src/style/Feet_Female_Realistic.jpg";

const FeetStep0 = ({ onSelect }) => {
    const { funnelCharData, updateCharacterData } = useFunnelCharacterContext();
    const [selectedStyle, setSelectedStyle] = useState(funnelCharData.style || 'Anime');
    const { t } = useTranslation();
    const location = useLocation();

    const styleOptions = [
        {
            name: 'Anime',
            image: AnimeStyle
        },
        {
            name: 'Realistic',
            image: RealStyle
        }
    ];

    useEffect(() => {
        if (location.pathname === '/create-feet') {
            updateCharacterData({
                gender: 'Feet',
            });
        }
    }, [location.pathname, updateCharacterData]);

    // Set initial state if there's existing data
    useEffect(() => {
        if (funnelCharData.style) {
            setSelectedStyle(funnelCharData.style);
        }
    }, [funnelCharData.style]);

    const handleStyleSelect = (style) => {
        setSelectedStyle(style);

        // Update both context and parent component
        const updatedData = {
            style: style,
        };

        updateCharacterData(updatedData);

        // Call the onSelect prop to trigger auto-progression
        if (onSelect) {
            onSelect(updatedData);
        }
    };

    return (
        <div>
            <div className="general-multiple-options-container"
                 style={{marginTop: '0'}}
            >
                <div className="funnel-creation-label" style={{marginBottom: '0'}}>
                    {t('StyleTitle')}
                </div>
                <div className="top-row">
                    {styleOptions.map((style, index) => (
                        <div
                            key={`${style.name}-${index}`}
                            className="general-option-wrapper"
                            role="button"
                            tabIndex={0}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    handleStyleSelect(style.name);
                                }
                            }}
                        >
                            <div
                                className={`general-option-creator-multiple-items ${selectedStyle === style.name ? 'general-option-creator-selected' : ''}`}
                                onClick={() => handleStyleSelect(style.name)}
                            >
                                <img
                                    src={style.image}
                                    alt={t(style.name)}
                                    className={`general-image-creator ${selectedStyle === style.name ? 'selected' : ''}`}
                                    style={{
                                        pointerEvents: 'none',
                                        objectPosition: 'bottom',
                                        aspectRatio: '1/1.25',
                                    }}
                                />
                                <span className='enforced-display-char-creator-small'>
                                    {t(style.name)}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FeetStep0;