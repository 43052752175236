import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useFunnelCharacterContext} from "../FunnelContext";

// Anime Images
import AnimeFCurly from "../Other/Trans/src/hairStyles/universal/anime/AnimeFCurly.jfif";
import AnimeFShort from "../Other/Trans/src/hairStyles/universal/anime/AnimeFShort.jfif";
import AnimeFStraight from "../Other/Trans/src/hairStyles/universal/anime/AnimeFStraight.jfif";

// Real Images
import RealFCurly from "../Other/Trans/src/hairStyles/universal/real/RealFCurly.jfif";
import RealFShort from "../Other/Trans/src/hairStyles/universal/real/RealFShort.jfif";
import RealFStraight from "../Other/Trans/src/hairStyles/universal/real/RealFStraight.jfif";

const GeneralHairColorStep = ({onSelect}) => {
    const {isAnime, funnelCharData, updateCharacterData} = useFunnelCharacterContext();
    const [selectedHairStyle, setSelectedHairStyle] = useState(funnelCharData.hairStyle || '');
    const [selectedHairColor, setSelectedHairColor] = useState(funnelCharData.hairColor || '');
    const {t} = useTranslation();

    const hairDetail = {
        hairStyle: [
            {name: 'Curly', image: isAnime ? AnimeFCurly : RealFCurly},
            {name: 'Short', image: isAnime ? AnimeFShort : RealFShort},
            {name: 'Long', image: isAnime ? AnimeFStraight : RealFStraight},
        ],
        hairColor: [
            {name: 'Blonde', color: '#d9b380'},
            {name: 'Black', color: '#2C2C2C'},
            {name: 'Brown', color: '#6d4730'},
            {name: 'Pink', color: '#e27589'},
        ],
    };

    useEffect(() => {
        const hasExistingData = funnelCharData.hairStyle || funnelCharData.hairColor;
        if (hasExistingData) {
            setSelectedHairStyle(funnelCharData.hairStyle || '');
            setSelectedHairColor(funnelCharData.hairColor || '');
        }
        // eslint-disable-next-line
    }, []);

    const handleColorSelect = (color) => {
        setSelectedHairColor(color);
        const updatedData = {
            ...funnelCharData,
            hairColor: color
        };
        updateCharacterData(updatedData);
        if (onSelect && selectedHairStyle) {
            onSelect(updatedData);
        }
    };

    const handleStyleSelect = (style) => {
        setSelectedHairStyle(style);
        const updatedData = {
            ...funnelCharData,
            hairStyle: style
        };
        updateCharacterData(updatedData);
        if (onSelect && selectedHairColor) {
            onSelect(updatedData);
        }
    };

    // useEffect(() => {
    //     checkAndProgress();
    // }, [selectedHairStyle, selectedHairColor, checkAndProgress]);

    return (
        <div className="general-multiple-options-container" style={{marginTop: '0'}}>
            <span className="funnel-creation-label">{t('tSelectHairStyle')}</span>
            <div className="top-row">
                {hairDetail.hairStyle.map((part, index) => (
                    <div
                        key={`style-${part.name}-${index}`}
                        className="general-option-wrapper"
                        role="button"
                        tabIndex={0}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                handleStyleSelect(part.name);
                            }
                        }}
                    >
                        <div
                            className={`general-option-creator-multiple-items ${selectedHairStyle === part.name ? 'general-option-creator-selected' : ''}`}
                            onClick={() => handleStyleSelect(part.name)}
                        >
                            <img
                                src={part.image}
                                alt={t(part.name)}
                                className={`general-image-creator ${selectedHairStyle === part.name ? 'selected' : ''}`}
                                style={{pointerEvents: 'none'}}
                            />
                            <span className='enforced-display-char-creator-small'
                                  style={{fontSize: '0.65em', left: '10px'}}
                            >{t(part.name)}</span>
                        </div>
                    </div>
                ))}
            </div>

            <span className="funnel-creation-label">{t('tSelectHairColor')}</span>
            <div className="bottom-row-color">
                {hairDetail.hairColor.map((part, index) => (
                    <div
                        key={`color-${part.name}-${index}`}
                        className="general-option-wrapper"
                        role="button"
                        tabIndex={0}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                handleColorSelect(part.name);
                            }
                        }}
                    >
                        <div
                            className={`general-option-creator-multiple-items ${selectedHairColor === part.name ? 'general-option-creator-selected' : ''}`}
                            onClick={() => handleColorSelect(part.name)}
                        >
                            <div
                                className="color-box"
                                style={{
                                    backgroundColor: part.color,
                                }}
                            />
                            <span className='enforced-display-char-creator-small'
                                  style={{fontSize: '0.65em', left: '10px'}}
                            >{t(part.name)}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GeneralHairColorStep;