import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useFunnelCharacterContext} from "../../FunnelContext";

// Anime Images
import AnimeSmallTits from "./src/breasts/anime/smalltitsanime(1).jpeg";
import AnimeMediumTits from "./src/breasts/anime/mediumtitsanime(1).jpeg";
import AnimeLargeTits from "./src/breasts/anime/bittitsanime(1).jpeg";
import AnimeSmallPenis from "./src/penis/anime/animesmallpenis(1).jpeg";
import AnimeMediumPenis from "./src/penis/anime/animebigpenis(1).jpeg";
import AnimeLargePenis from "./src/penis/anime/animebiggerpenis(1).jpeg";

// Real Images
import RealSmallTits from "./src/breasts/real/smalltitsreal(1).jpeg";
import RealMediumTits from "./src/breasts/real/mediumtitsrealreal(1).jpeg";
import RealLargeTits from "./src/breasts/real/bigtitsreal(1).jpeg";
import RealSmallPenis from "./src/penis/real/realsmallpenis(1).jpeg";
import RealMediumPenis from "./src/penis/real/realmediumpenis(1).jpeg";
import RealLargePenis from "./src/penis/real/realBIGpenis(1).jpeg";

const TransStep2 = ({ onSelect }) => {
    const {isAnime, funnelCharData, updateCharacterData} = useFunnelCharacterContext();
    const [selectedBreastSize, setSelectedBreastSize] = useState(funnelCharData.breastSize || 'Medium');
    const [selectedPenisSize, setSelectedPenisSize] = useState(funnelCharData.dickSize || 'Medium');
    const {t} = useTranslation();

    const bodyParts = {
        breasts: [
            {name: 'Small', image: isAnime ? AnimeSmallTits : RealSmallTits},
            {name: 'Medium', image: isAnime ? AnimeMediumTits : RealMediumTits},
            {name: 'Large', image: isAnime ? AnimeLargeTits : RealLargeTits},
        ],
        penis: [
            {name: 'Small', image: isAnime ? AnimeSmallPenis : RealSmallPenis},
            {name: 'Medium', image: isAnime ? AnimeMediumPenis : RealMediumPenis},
            {name: 'Large', image: isAnime ? AnimeLargePenis : RealLargePenis},
        ],
    };

    const handleBreastSelect = (size) => {
        setSelectedBreastSize(size);
        updateCharacterData({breastSize: size});
    };

    const handlePenisSelect = (size) => {
        setSelectedPenisSize(size);
        updateCharacterData({dickSize: size});
    };

    return (
        <div className="general-multiple-options-container" style={{marginTop: 'unset'}}>
            <span className="funnel-creation-label">{t('tSelectBreastSize')}</span>
            <div className="top-row">
                {bodyParts.breasts.map((part, index) => (
                    <div key={`breast-${part.name}-${index}`} className="general-option-wrapper">
                        <div
                            className={`general-option-creator-multiple-items ${selectedBreastSize === part.name ? 'general-option-creator-selected' : ''}`}
                            onClick={() => handleBreastSelect(part.name)}
                        >
                            <img
                                src={part.image}
                                alt={part.name}
                                className={`general-image-creator ${selectedBreastSize === part.name ? 'selected' : ''}`}
                                style={{pointerEvents: 'none', objectPosition: 'top'}}
                            />
                            <span className='enforced-display-char-creator-small'
                                  style={{fontSize: '0.65em', left: '10px'}}
                            >{t(part.name)}
                            </span>
                        </div>
                    </div>
                ))}
            </div>

            <span className="funnel-creation-label">{t('tSelectPenisSize')}</span>
            <div className="bottom-row">
                {bodyParts.penis.map((part, index) => (
                    <div key={`penis-${part.name}-${index}`} className="general-option-wrapper">
                        <div
                            className={`general-option-creator-multiple-items ${selectedPenisSize === part.name ? 'general-option-creator-selected' : ''}`}
                            onClick={() => handlePenisSelect(part.name)}
                        >
                            <img
                                src={part.image}
                                alt={part.name}
                                className={`general-image-creator ${selectedPenisSize === part.name ? 'selected' : ''}`}
                                style={{pointerEvents: 'none', objectPosition: 'bottom'}}
                            />
                            <span className='enforced-display-char-creator-small'
                                  style={{fontSize: '0.65em', left: '10px'}}
                            >{t(part.name)}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TransStep2;