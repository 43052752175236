import React, { useState, useEffect } from 'react';
import {ReactComponent as CloseIcon} from "../../../../icons/cross.svg";
import {ReactComponent as BookIcon} from "../../icons/book.svg";
import './VoiceCloningLibrary.css';
import CustomAudioPlayer from "./CustomAudioPlayer";
import {useAdvancedCharacterCreation} from "../../../AdvancedCharacterCreation/AdvancedCharacterContext";

const VoiceLibraryButton = ({onClick}) => (
    <div style={{width: '100%'}}>
        <button
            className="audio-upload-label"
            style={{marginTop: '15px'}}
            onClick={onClick}
        >
            <BookIcon/>
            Voice Library
        </button>
    </div>
);

const VoiceLibraryPopup = ({
                               isOpen,
                               setIsOpen,
                               voices,
                               isLoading,
                               error,
                               updateCharacterData,
                               currentVoiceOptionActive,
                               setCurrentVoiceOptionActive
                           }) => {
    const [selectedVoiceId, setSelectedVoiceId] = useState(null);
    const [isPlaying, setIsPlaying] = useState({});
    const [audioDurations, setAudioDurations] = useState({});
    const {  updateAdvancedCharacterData } = useAdvancedCharacterCreation();

    // Add this function
    const calculateAudioDuration = (url) => {
        return new Promise((resolve) => {
            const audio = new Audio();
            audio.src = url;

            audio.addEventListener('loadedmetadata', () => {
                const duration = audio.duration;
                resolve(duration);
            });
        });
    };

    // Add this effect to calculate durations when voices change
    useEffect(() => {
        const loadDurations = async () => {
            const durations = {};
            for (const voice of voices) {
                if (voice.voice_sample) {
                    durations[voice.voice_id] = await calculateAudioDuration(voice.voice_sample);
                }
            }
            setAudioDurations(durations);
        };

        if (voices) {
            loadDurations();
        }
    }, [voices]);

    const handleApplyVoice = (voice) => {

        if (window.location.href === 'https://elyza.app/advanced-creator') {
            updateAdvancedCharacterData({ voiceSelection: voice.voice_id });
        } else if (window.location.href === 'https://elyza.app/create-character') {
            updateCharacterData({ voiceSelection: voice.voice_id });
        }
        setSelectedVoiceId(voice.voice_id);
        setIsOpen(false);
        setCurrentVoiceOptionActive('library')
    };


    if (currentVoiceOptionActive === 'upload' || currentVoiceOptionActive === 'record') {
        return null;
    }

    return (
        <>
            <VoiceLibraryButton onClick={() => setIsOpen(true)}/>

            {isOpen && voices && (
                <div className="modal-backdrop">
                    <div className="modal-content-notification">
                        <div className="notification-label">
                            Voice Library
                            <div
                                className="modal-close-icon-notification"
                                onClick={() => setIsOpen(false)}
                            >
                                <CloseIcon/>
                            </div>
                        </div>

                        <div className="gap-between-notification">
                            {isLoading ? (
                                <div className="loader"></div>
                            ) : error ? (
                                <div className="error-text">{error}</div>
                            ) : (
                                <div className="voice-list">
                                    {voices.map((voice) => (

                                        <>
                                        <div key={voice.id} className="voice-item">
                                            <div className="voice-info">
                                                <span className="sublabel-notification">
                                                    {voice.name}
                                                </span>
                                                {voice.description && (
                                                    <span className="voice-description">
                                                        {voice.description}
                                                    </span>
                                                )}
                                            </div>

                                            <div className="button-container-cloning">
                                                <button
                                                    className="play-button"
                                                    onClick={() => setIsPlaying(prev => ({
                                                        ...prev,
                                                        [voice.id]: !prev[voice.id]
                                                    }))}
                                                >
                                                    {isPlaying[voice.id] ? 'Stop' : 'Play'}
                                                </button>
                                                <div className="button-divider"></div>
                                                <button
                                                    className="play-button"
                                                    onClick={() => handleApplyVoice(voice)}
                                                    disabled={selectedVoiceId === voice.id}
                                                >
                                                    {selectedVoiceId === voice.id ? 'Applied' : 'Apply'}
                                                </button>
                                            </div>
                                        </div>
                                            {isPlaying[voice.id] && (
                                                <div className="audio-preview-container">
                                                    <CustomAudioPlayer
                                                        src={voice.voice_sample}
                                                        duration={audioDurations[voice.voice_id]}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    ))}
                                </div>
                            )}
                        </div>

                        <button
                            onClick={() => setIsOpen(false)}
                            className="notification-button-main"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default VoiceLibraryPopup;