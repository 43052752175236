import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';

const FunnelCharacterContext = createContext();

export const useFunnelCharacterContext = () => useContext(FunnelCharacterContext);

export const FunnelCharacterProvider = ({children}) => {
    //eslint-disable-next-line
    const defaultData = {
        charName: '',
        style: '',
        gender: '',
        ethnicity: '',
        hairColor: '',
        hairStyle: '',
        breastSize: '',
        dickSize: '',
        buttSize: '',
        personality: '',
        scenario: '',
        voiceSelection: '',
        languageSelection: '',
        specialCase: '',
        nailPolish: '',
    };

    // Step validation mapping
    const stepValidationMap = {
        trannyGeneral: {
            0: (data) => Boolean(data.style),
            1: (data) => Boolean(data.ethnicity),
            2: (data) => Boolean(data.hairColor && data.hairStyle),
            3: (data) => Boolean(data.breastSize && data.dickSize),
            4: (data) => Boolean(data.voiceSelection && data.personality),
            5: (data) => Boolean(data.scenario)
        },
        feetGeneral: {
            0: (data) => Boolean(data.style),
            1: (data) => Boolean(data.ethnicity),
            2: (data) => Boolean(data.hairColor && data.hairStyle),
            3: (data) => Boolean(data.breastSize && data.buttSize),
            4: (data) => Boolean(data.nailPolish),
            5: (data) => Boolean(data.voiceSelection && data.personality),
            6: (data) => Boolean(data.scenario)
        }
    };

    const [funnelCharData, setFunnelCharData] = useState(() => {
        try {
            const savedData = localStorage.getItem('funnelCharacterData');
            if (savedData) {
                const parsedData = JSON.parse(savedData);
                if (parsedData && typeof parsedData === 'object') {
                    return {
                        ...defaultData,
                        ...parsedData
                    };
                }
            }
        } catch (error) {
            console.error('Error loading funnel data from localStorage:', error);
        }
        return defaultData;
    });

    const [currentStep, setCurrentStep] = useState(0);
    const [isAnime, setIsAnime] = useState(funnelCharData.style === 'Anime');

    useEffect(() => {
        setIsAnime(funnelCharData.style === 'Anime');
    }, [funnelCharData.style]);

    const clearOptions = useCallback(() => {
        setFunnelCharData(defaultData);
        setCurrentStep(0);
        localStorage.removeItem('funnelCharacterData');
    }, [defaultData]);

    const updateCharacterData = useCallback((newData) => {
        setFunnelCharData(prevData => {
            const updatedData = {
                ...prevData,
                ...newData
            };
            localStorage.setItem('funnelCharacterData', JSON.stringify(updatedData));
            return updatedData;
        });
    }, []);

    const isStepValid = useCallback((step, version) => {
        if (!stepValidationMap[version]) return false;
        const validator = stepValidationMap[version][step];
        return validator ? validator(funnelCharData) : false;
        //eslint-disable-next-line
    }, [funnelCharData]);

    const goToNextStep = useCallback((totalSteps, version) => {
        if (currentStep < totalSteps - 1 && isStepValid(currentStep, version)) {
            setCurrentStep(prev => prev + 1);
            return true;
        }
        return false;
    }, [currentStep, isStepValid]);

    const goToPreviousStep = useCallback(() => {
        if (currentStep > 0) {
            setCurrentStep(prev => prev - 1);
            return true;
        }
        return false;
    }, [currentStep]);

    return (
        <FunnelCharacterContext.Provider value={{
            funnelCharData,
            updateCharacterData,
            clearOptions,
            isAnime,
            currentStep,
            setCurrentStep,
            isStepValid,
            goToNextStep,
            goToPreviousStep
        }}>
            {children}
        </FunnelCharacterContext.Provider>
    );
};

export default FunnelCharacterProvider;