import React, {useEffect, useState} from 'react';
import '../../Notifications/ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../../MainMenuPage/icons/cross.svg";
import {useAdvancedCharacterCreation} from "../../../pages/AdvancedCharacterCreation/AdvancedCharacterContext";
import {useTranslation} from "react-i18next";

const AdvancedReviewScreen = ({isOpen, onClose, onConfirm, onReturnToStep}) => {
    const {advancedCharData} = useAdvancedCharacterCreation();
    const [skippedItems, setSkippedItems] = useState([]);
    const [isChecked, setIsChecked] = useState(false);

    const {t} = useTranslation();

    useEffect(() => {
        const requiredFields = [
            'style',
            'nsfw',
            'gender',
            'isPublic',
            'charName',
            'description',
            'appearance',
            'personality',
            'scenario',
            'dialogue',
            'age',
            'languageSelection', 'voiceSelection'
        ];

        const skippedItems = requiredFields.filter(field => {
            const value = advancedCharData[field];
            if (field === 'nsfw' || field === 'isPublic') {
                return value === undefined;
            } else {
                return !value || (typeof value === 'string' && value.trim() === '');
            }
        });

        setSkippedItems(skippedItems);

    }, [advancedCharData]);

    const getStepName = (field) => {
        const stepNames = {
            style: t("StyleSetting"),
            gender: t("GenderSetting"),
            nsfw: t("SafetySetting"),
            isPublic: t("PrivacySetting"),
            charName: t("CharacterName"),
            description: t("Description"),
            appearance: t("Appearance"),
            personality: t("Personality"),
            scenario: t("Scenario"),
            dialogue: t("FirstMessage"),
            age: t("Age"),
            languageSelection: t("LanguageSelection"),
            voiceSelection: t("VoiceSelection"),
        };
        return stepNames[field] || field;
    };

    const handleSubmitCharacter = () => {
        onConfirm();
        onClose();
    }

    const handleCompleteMissingSteps = () => {
        if (skippedItems.length > 0) {
            const firstMissedStep = getStepName(skippedItems[0]);
            onReturnToStep(firstMissedStep);
            onClose();
        }
    }

    const finalButtonText = () => {
        if (skippedItems.length > 0) {
            return t('CompleteMissingSteps');
        } else if (advancedCharData.clonedVoiceURL && advancedCharData.clonedVoiceName) {
            return 'Continue (10 Tokens)';
        } else {
            return t('Continue');
        }
    }

    const [isDisabled, setShouldDisable] = useState(true)

    useEffect (() => {
        if (isChecked && advancedCharData.clonedVoiceURL) {
            setShouldDisable(false)
        } else if (!advancedCharData.clonedVoiceURL) {
            setShouldDisable(false)
        } else {
            setShouldDisable(true)
        }

    }, [isChecked, advancedCharData.clonedVoiceURL]);

    const [AreWeCloning, setAreWeCloning] = useState(false)

    useEffect(() => {
        if (advancedCharData.clonedVoiceURL === '' ) {
            setAreWeCloning(false)
        } else if (advancedCharData.clonedVoiceURL && advancedCharData.clonedVoiceName) {
            setAreWeCloning(true)
        }
    }, [advancedCharData.voiceSelection, advancedCharData.clonedVoiceName, advancedCharData.clonedVoiceURL]);

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop"
             style={{alignItems: 'center'}}
        >
            <div className="modal-content-notification"
                 style={{
                     borderRadius: '16px'
                 }}
            >
                <div className='notification-label'>
                    ✋ {t('WaitASecond')}
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className='sublabel-notification'>
                    {skippedItems.length > 0 ? (
                        <>
                            <p>{t('MissedStepsMessage')}</p>
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
                                gap: '10px',
                                marginTop: '15px',
                                maxHeight: '300px',
                                overflowY: 'auto',
                            }}>
                                {skippedItems.map((item, index) => (
                                    <button
                                        key={index}
                                        onClick={() => {
                                            onReturnToStep(getStepName(item));
                                            onClose();
                                        }}
                                        style={{
                                            background: 'none',
                                            border: '1px solid #C4C4C4',
                                            color: '#C4C4C4',
                                            padding: '8px 12px',
                                            borderRadius: '6px',
                                            cursor: 'pointer',
                                            transition: 'all 0.3s ease',
                                        }}
                                        onMouseEnter={(e) => {
                                            e.target.style.background = '#262626';
                                            e.target.style.color = 'white';
                                        }}
                                        onMouseLeave={(e) => {
                                            e.target.style.background = 'none';
                                            e.target.style.color = '#C4C4C4';
                                        }}
                                    >
                                        {getStepName(item)}
                                    </button>
                                ))}
                            </div>
                            <p style={{marginTop: '15px', marginBottom: '-10px'}}>{t('ClickStepToComplete')}</p>

                        </>
                    ) : (
                        <>
                            {t('ReviewBeforeSubmission')}
                            <br/><br/>
                            {t('CanEditLater')}

                            {AreWeCloning && (
                                <>
                                    <br/><br/>
                                    <div className="checkbox-container">
                                        <label className="checkbox-label">
                                            <input
                                                type="checkbox"
                                                className="checkbox-input"
                                                checked={isChecked}
                                                onChange={() => setIsChecked(!isChecked)}
                                            />
                                            <div className="checkbox-square">
                                                <svg viewBox="0 0 24 24" className="checkbox-svg">
                                                    <polyline points="20 6 9 17 4 12"/>
                                                </svg>
                                            </div>
                                            <span>{t('voice_clone_terms')}</span>
                                        </label>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>

                <button
                    onClick={skippedItems.length > 0 ? handleCompleteMissingSteps : handleSubmitCharacter}
                    className="notification-button-main"
                    disabled={isDisabled}
                    style={{
                        transition: 'all 0.3s ease',
                    }}
                    onMouseEnter={(e) => {
                        if (!skippedItems.length) {
                            e.target.style.background = '#915ae8';
                        }
                    }}
                    onMouseLeave={(e) => {
                        if (!skippedItems.length) {
                            e.target.style.background = '';
                        }
                    }}
                >
                    <span>{finalButtonText()}</span>
                </button>
            </div>
        </div>
    );
};

export default AdvancedReviewScreen;