import React, { useState } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useTranslation } from "react-i18next";
import { useFunnelCharacterContext } from "../../FunnelContext";

// Anime Images
import AnimeWhite from "./src/ethnicity/anime/whiteanime(1).jpeg";
import AnimeLatina from "./src/ethnicity/anime/animelatina(1).jpeg";
import AnimeBlack from "./src/ethnicity/anime/blackanime(1).jpeg";

// Real Images
import RealWhite from "./src/ethnicity/real/whiterealistic(1).jpeg";
import RealLatina from "./src/ethnicity/real/latinarealistic(1).jpeg";
import RealBlack from "./src/ethnicity/real/blackrealistic(1).jpeg";

const TransStep1 = ({ onSelect }) => {
    const {isAnime, funnelCharData, updateCharacterData } = useFunnelCharacterContext();
    const [selectedEthnicity, setSelectedEthnicity] = useState(funnelCharData.ethnicity || 'White');
    const { t } = useTranslation();

    const ethnicityList = [
        {
            name: 'White',
            image: isAnime ? AnimeWhite : RealWhite
        },
        {
            name: 'Latina',
            image: isAnime ? AnimeLatina : RealLatina
        },
        {
            name: 'Black',
            image: isAnime ? AnimeBlack : RealBlack
        },
    ];

    const handleEtnicitySelect = (ethnicity) => {
        setSelectedEthnicity(ethnicity);

        const updatedData = {
            ethnicity: ethnicity
        };

        updateCharacterData({ ethnicity: ethnicity });

        if (onSelect) {
            onSelect(updatedData);
        }
    };

    return (
        <div>
            <div className="general-multiple-options-container"
                 style={{marginTop: '0'}}
            >
                <div className="funnel-creation-label" style={{marginBottom: '0'}}>
                    {t('EthnicityTitle')}
                </div>
                <div className="top-row">
                    {ethnicityList.map((style, index) => (
                        <div key={`${style.name}-${index}`} className="general-option-wrapper">
                            <div
                                className={`general-option-creator-multiple-items ${selectedEthnicity === style.name ? 'general-option-creator-selected' : ''}`}
                                onClick={() => handleEtnicitySelect(style.name)}
                            >
                                <img
                                    src={style.image}
                                    alt={style.name}
                                    className={`general-image-creator ${selectedEthnicity === style.name ? 'selected' : ''}`}
                                    style={{
                                        pointerEvents: 'none',
                                        objectPosition: 'center',
                                        aspectRatio: '1/1.45'
                                    }}
                                />
                                <span className='enforced-display-char-creator-small'>
                                    {t(style.name)}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TransStep1;